@import "../../index.module";

.container {
    .landmark-header {
        color: #ffffff;
        font-family: $themeFontMediumBold;
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 280px;
    }

    .sub-header {
        opacity: 0.73;
        font-family: inherit;
        font-size: 15px;
        letter-spacing: -0.4px;
        color: #ffffff;
    }

    .address {
        opacity: 0.87;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.4px;
        color: #ffffff;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .card {
        width: 100%;
        min-height: 174px;
        -webkit-backdrop-filter: blur(2.3px);
        backdrop-filter: blur(2.3px);
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)), url('../../assets/LM_BG_image.png');
        position: relative;
        box-shadow: none;
        border-radius: 0;

        .card-content {
            padding: 15px;

            .card-content-bottom {
                position: absolute;
                width: 360px;
                bottom: 0;

                .phone {
                    display: block;
                    width: 45%;
                    opacity: 0.73;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    color: #ffffff;
                    float: left;
                }

                .group-name {
                    display: inline-block;
                    width: 45%;
                    opacity: 0.73;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    color: #ffffff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .back_button {
        flex: auto;
        border: none;
        padding-left: 2px;
        padding-top: 3px;
        text-decoration: none;
        margin-top: 0px;
        position: relative;
        cursor: pointer;
        height: 22px;
        font-size: 18px;
        letter-spacing: -0.5px;
        text-align: left;
        color: #ffffff;
        outline: none;
        background-color: transparent;

        i {
            width: 18px;
            height: 18px;
            display: inline-block;
            background-position: 0;

            &.back_icon {
                background: url("../../assets/icons/back.png") no-repeat;
            }
        }

        span {
            display: inline-block;
            top: -3px;
            position: relative;
        }
    }
}

.detailLoader {
    margin: 50px;
    text-align: center;
}

.no-assets-in-landmark {
    margin: 80px 20px;
    text-align: center;
}

.assets-in-landmark {
    height: 69px;

    .header {
        padding: 6px 16px;

        .main-header {
            font-size: 24px;
            letter-spacing: -0.7px;
            color: #000000;
        }

        .asset-sub-header {
            opacity: 0.73;
            font-size: 16px;
            letter-spacing: -0.4px;
            color: rgba(0, 0, 0, 0.64);
            margin-bottom: 0px;
        }
    }
}

.header-border {
    height: 2px;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.3);
}

.list-border {
    position: relative;
    width: 79%;
    height: 2px;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.5);
    left: 72px;
}

.list-item {
    padding: 10.5px 17.5px 11.5px 24px;

    .asset-avatar {
        background-color: #2cc5b4;

        i {
            width: 24px;
            height: 17px;
            object-fit: contain;
            position: relative;
            background-position: 0;
            display: block;
            margin-left: 3px;
            margin-top: 2px;

            &.truck {
                background: url("../../assets/icons/assets/truck.svg") no-repeat;
            }

            &.equipment {
                margin: auto;
                background: url("../../assets/icons/assets/equipment.svg") no-repeat;
            }

            &.trailer {
                background: url("../../assets/icons/assets/trailer.svg") no-repeat;
            }

            &.vehicle {
                background: url("../../assets/icons/assets/vehicle.svg") no-repeat;
            }
        }
    }

    .tooltip {
        position: absolute;
        display: inline-block;
        top: 8px;
        z-index: 2;
        left: 48px;
        font-size: 18px;

        + .tooltipText {
            visibility: hidden;
            display: inline-block;
            color: #000;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
            background-color: #ffffff;
            text-align: center;
            border-radius: 5px;
            padding: 5px 10px;
            
            /* Position the tooltip */
            position: absolute;
            z-index: 3;
            top: 40%;
            left: 18%;
            margin-left: -60px;
            font-size: 12.5px;
            line-height: 1.52;
            letter-spacing: 0.09px;
        }

        &:hover {
            ~ .tooltipText{
                visibility: visible;
            }
        }
      }

    .asset-name-width {
        width: 200px;
    }

    .asset-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 19px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.87);
    }

    .movement-and-last-location {
        font-size: 14px;
        line-height: 1.36;
        letter-spacing: 0.1px;
        overflow: hidden;
        text-overflow: ellipsis;

        .last_reporting_text {
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.09;
            color: rgba(0, 0, 0, 0.5);
            white-space: nowrap;
        }

        .Moving {
            color: #689f38;
        }

        .Stopped {
            color: #d32f2f;
        }

        .Idle {
            color: #f57c00;
        }

        .asset-status-null {
            color: rgba(0, 0, 0, 0.5);
        }

        .last-location {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .asset-direction-and-direction-icon {
        padding-top: 2px;
    }

    .asset_speed_dir_container {
        display: inline-block;
        margin-right: 10px;
        position: absolute;
        right: 0;

        .speed_dir_txt {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            margin: 0;
        }

        .asset_dir_container {
            padding-top: 20px;

            i {
                float: right;
                width: 14px;
                height: 14px;
                margin-left: 5px;
                margin-top: 2px;
                background: url("../../assets/icons/arrow.svg") no-repeat;

                &.NE_dir {
                    @include icon_rotate_mixin(45);
                }

                &.E_dir {
                    @include icon_rotate_mixin(90);
                }

                &.SE_dir {
                    @include icon_rotate_mixin(135);
                }

                &.S_dir {
                    @include icon_rotate_mixin(180);
                }

                &.SW_dir {
                    @include icon_rotate_mixin(225);
                }

                &.W_dir {
                    @include icon_rotate_mixin(270);
                }

                &.NW_dir {
                    @include icon_rotate_mixin(315);
                }
            }
        }
    }
}