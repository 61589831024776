@import "../../index.module";

.container {
    .asset_list_header_title {
        color: #ffffff;
        font-family: $themeFontMediumBold;
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 302px;
    }

    .search_icon_white {
        background: url("../../assets/icons/search-white.svg") no-repeat center;
        cursor: pointer;
        height: 27px;
        outline: none;
        position: relative;
        right: -15px;
        width: 18px;
    }

    .search_box_container {
        display: inline-block;
        flex-grow: 1;
        height: 30px;
        overflow: hidden;
        position: relative;
        width: 345px;

        .search_box {
            height: 100%;
            width: 100%;
            background-color: white;
            display: inline-block;
            border-radius: 2px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

            .asset_search_input {
                input {
                    font-size: 14px;
                }

                .search_icon {
                    background: url("../../assets/icons/search.svg") no-repeat;
                    height: 18px;
                    margin-left: 16px;
                    outline: none;
                    width: 18px;
                }

                .cancel_search {
                    background: url("../../assets/icons/close.svg") no-repeat;
                    cursor: pointer;
                    height: 14px;
                    margin-right: 12px;
                    outline: none;
                    width: 14px;
                }
            }
        }
    }

    .asset_list_subheader_title {
        color: #ffffff;
        font-family: inherit;
        font-size: 16px;
        letter-spacing: -0.4px;
        opacity: 0.73;
    }

    .asset_list_header_card {
        width: 100%;
        min-height: 90px;
        background-color: #343e48;
        box-shadow: none;
        border-radius: 0;

        .card_content {
            padding: 7px 15px;
        }
    }

    .available_assets {
        display: block;

        .available_assets_header {
            width: 100%;
            min-height: 40px;
            background-color: #fff;
            border-radius: 0;
            color: #007aff;
            font-size: 14px;
            font-weight: 500;

            .card_content {
                padding: 12px 15px;
            }
        }

        .available_assets_list {
            background-color: #fff;
            height: calc(100vh - 196px);
        }
    }
}
